<template>
  <div>
    <p>
      To configure your new number for {{ virtualPhoneToConfigure.company_name }},
      choose an area code:
      <br>
    </p>

    <div class="jurisdiction-selection">
      <bandwidth-jurisdiction-select @input="setSelectedAreaCode" />
    </div>

    <ct-centered-spinner v-if="isLoading" />
    <h6 v-if="hasLoadedButNoNumbersWereFound" class="no-numbers-found-text">
      No {{ selectedAreaCode.code }} area codes are available. Please select a different option.
    </h6>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Step1',
  components: {
    BandwidthJurisdictionSelect: () => import('@/components/BandwidthJurisdictionSelect'),
    CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
  },

  data () {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'availableNumbersFinished',
      'selectedAreaCode',
      'availableNumbers',
      'virtualPhoneToConfigure',
    ]),

    isLoading() {
      return this.selectedAreaCode && !this.availableNumbersFinished
    },

    hasLoadedButNoNumbersWereFound() {
      return this.availableNumbersFinished && this.availableNumbers.length === 0
    },
  },

  methods: {
    ...mapActions('virtualPhones', ['setSelectedAreaCode', 'loadVirtualPhones']),
  },
}
</script>

<style lang="scss">

.virtual-phone-setup-dialog {
  margin-top: 150px;

  .phone-setup-modal-body {
    margin: 50px 60px 30px;
    font-size: 21px;
    line-height: 32px;
    color: #47464B;
  }

  .jurisdiction-selection {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .close-modal-button {
    margin-top:20px;
  }
  .close-modal-row{
    margin-bottom:0px;
  }
  .no-numbers-found-text{
    margin: 5px 0;
  }
}

</style>
